exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-gerer-ma-boite-en-js": () => import("./../../../src/pages/work/GererMaBoite.en.js" /* webpackChunkName: "component---src-pages-work-gerer-ma-boite-en-js" */),
  "component---src-pages-work-gerer-ma-boite-js": () => import("./../../../src/pages/work/GererMaBoite.js" /* webpackChunkName: "component---src-pages-work-gerer-ma-boite-js" */),
  "component---src-pages-work-hotel-appz-en-js": () => import("./../../../src/pages/work/HotelAppz.en.js" /* webpackChunkName: "component---src-pages-work-hotel-appz-en-js" */),
  "component---src-pages-work-hotel-appz-js": () => import("./../../../src/pages/work/HotelAppz.js" /* webpackChunkName: "component---src-pages-work-hotel-appz-js" */),
  "component---src-pages-work-index-en-js": () => import("./../../../src/pages/work/index.en.js" /* webpackChunkName: "component---src-pages-work-index-en-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */)
}

